<!--
 * @Author: Dyf
 * @Date: 2023-02-18 18:24:03
 * @LastEditors: Dyf
 * @LastEditTime: 2023-06-29 16:23
 * @Description: 学校介绍
-->
<style lang="scss" scoped>
.page-school--introduce {
  @include innerPage;
  @include pageTitle;

  .school-introduce {
    width: 100%;
    height: calc(100% - 38px);
    box-sizing: border-box;
    padding: 38px 18px 0 18px;

    .introduce {
      &-inner {
        width: 100%;
        height: 100%;
        overflow: hidden;
        overflow-y: auto;
        box-sizing: border-box;
        padding-right: 40px;

        &.scroll {
          padding-right: 32px;
        }
      }

      &-img {
        width: 100%;
        height: 388px;
        border-radius: 30px;
        position: relative;
        overflow: hidden;

        .el-image {
          width: 100%;
          height: 100%;
        }
      }

      &-info {
        width: 100%;
        min-height: 148px;
        max-height: 280px;
        box-sizing: border-box;
        padding: 20px 40px 20px 80px;
        background: rgba($color: #000, $alpha: 0.42);
        position: absolute;
        bottom: 0;
        left: 0;
        color: #f7f7fa !important;
        flex-direction: column;

        @include flexBox(center, flex-start);

        h3 {
          line-height: 1em;
          box-sizing: border-box;
          padding-right: 40px;
        }

        .desc {
          max-height: calc(100% - 1em - 18px);
          box-sizing: border-box;
          padding-right: 40px;
          overflow: hidden;
          overflow-y: auto;
          margin-top: 18px;
        }

        p {
          width: 100%;
          font-size: 16px;
          line-height: 2em;
          color: #f7f7fa !important;
        }
      }

      &-detail {
        margin-top: 24px;

        h4 {
          line-height: 2em;
          color: #2b2b2c;
          margin-bottom: 18px;
        }

        .detail-editor {
          font-size: 16px;
          color: #252525;
          line-height: 26px;
        }
      }

      &-history {
        margin-top: 38px;

        h4 {
          line-height: 2em;
          color: #2b2b2c;
          margin-bottom: 30px;
        }
      }
    }
  }
}
</style>
<template>
  <section class="page-school--introduce">
    <div class="page-title">
      <h3>学校介绍</h3>
      <div>
        <el-button type="custom_warning" size="small" @click="$router.push({ name: 'SCHOOL_MODIFY' })">
          编辑
        </el-button>
        <el-button type="custom_primary" size="small" @click="$router.go(-1)">
          返回
        </el-button>
      </div>
    </div>
    <div class="school-introduce">
      <div class="introduce-inner" ref="introduceInner" :class="{ scroll: pageScroll }" v-loading="dataLoad">
        <div class="introduce-img">
          <el-image :src="formatfile(schoolData.scsch_images)" fit="cover">
            <div slot="error" class="image-slot" style="text-align:center">
              <img src="@assets/images/no-data3.png" alt="">
              <p style="margin-top:10px;color:#959595">图片加载失败~</p>
            </div>
          </el-image>
          <div class="introduce-info">
            <h3 class="bold">{{ schoolData.scsch_name }}</h3>
            <div class="desc light" v-if="schoolData.scsch_remark">
              <p>
                简介：{{ schoolData.scsch_remark }}
              </p>
            </div>
          </div>
        </div>
        <div class="introduce-detail" v-if="schoolData.scsch_content">
          <h4 class="bold">详细介绍</h4>
          <div class="detail-editor" v-html="schoolData.scsch_content"></div>
        </div>
        <div class="introduce-history" v-if="!$isEmpty(schoolData.memorabilia)">
          <h4 class="bold">大事记</h4>
          <el-timeline>
            <el-timeline-item v-for="(activity, index) in schoolData.memorabilia" placement="top" :key="index"
              :timestamp="timeFormat(activity.scsch_mem_date)">
              <span class="iconfont" slot="dot">&#xe61d;</span>
              <p>{{ activity.scsch_mem_content }}</p>
            </el-timeline-item>
          </el-timeline>
        </div>
      </div>
    </div>
  </section>
</template>
  
<script>
import { schoolInfo } from "@api/school";
import { formatTime, formatFile } from "@utils";
export default {
  name: "home_schoolIntroduce",
  computed: {
    timeFormat() {
      return function (time) {
        return formatTime(time, 'YYYY-MM-DD')
      }
    },
    formatfile() {
      return function (url) {
        return formatFile(url)
      }
    }
  },
  data() {
    return {
      pageScroll: false,
      schoolData: {},
      dataLoad: false,
    };
  },
  mounted() {
    this.initHeight();
    /* 监听窗口大小变化 */
    window.addEventListener("resize", this.initHeight);
  },
  /** 监听页面离开 */
  beforeRouteLeave(to, from, next) {
    this.$destroy();
    next();
  },
  /** 销毁页面上窗口监听事件 */
  destroyed() {
    window.removeEventListener("resize", this.initHeight);
  },
  created() {
    this.init();
  },
  methods: {
    /** 格式化表格最大高度 */
    initHeight() {
      this.$nextTick(() => {
        setTimeout(() => {
          const elH = this.$refs.introduceInner;
          if (elH.scrollHeight > elH.clientHeight) this.pageScroll = true;
          else this.pageScroll = false;
          this.$forceUpdate();
        }, 200);
      });
    },
    /** 初始化数据 */
    async init() {
      this.dataLoad = true;
      const res = await schoolInfo();
      this.dataLoad = false;
      if (res.status == 200) {
        this.schoolData = res.data;
      }
    }
  },
};
</script>
  